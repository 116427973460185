















































import { ConsumoRequest, ConsumoResponse ,AgregarDetalleConsumo, ConsumoDetalleAddRequest} from '@/entidades/KTB/Consumo/ConsumoEntity';
import { PalletTablon, TrozasQuery } from '@/entidades/KTB/Planificacion/PlanificacionListado';
import { Planta } from '@/entidades/Maestro/Planta';
import Alerta from '@/entidades/Sistema/Alerta';
import Loading from '@/entidades/Sistema/Loading';
import {Vue,Component,Prop} from 'vue-property-decorator'
import { Validations } from 'vuelidate-property-decorators';
import { minValue, required } from 'vuelidate/lib/validators';
import {State,Action} from 'vuex-class';
@Component({
    name : 'ConsumoDetalleAdd'
})
export default class ConsumoDetalleAdd extends Vue
{
    @Action('changeAlerta',{namespace:'sistemaModule'}) changeAlerta!:Function;
    @Action('changeLoading',{namespace:'sistemaModule'}) changeLoading!:Function;
    @State('usuarioId',{namespace:'authModule'}) usuarioId!:string;
    @State('plantaAsignada',{namespace: 'authModule'}) plantas!:Array<Planta>;
    @Prop({type:Object,required:false}) consumo!:ConsumoResponse;
    @Prop({type:Array,required:false}) trozas!:Array<TrozasQuery>;
    @Prop({type:Array,required:false}) tablon!:Array<TrozasQuery>;
    listadoTrozaSeleccionada:Array<TrozasQuery> =[];
    listadoTablonSeleccionada:Array<PalletTablon> =[];
    header:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"Despacho",sortable:true,value:'numeroDespacho'},
            {text:"Formato",sortable:false,value:'formatoName'},
            {text:"T2K",sortable:true,value:'t2K'},
            {text:"Volumen",sortable:true,value:'volumen'},                     
    ];

    headerDetalle:{text:string,sortable:boolean,value:string}[] =
    [
            {text:"# Pallet",sortable:false,value:'palletId'},
            {text:"Espesor",sortable:true,value:'espesor'},
            {text:"Volumen",sortable:true,value:'volumen'},                     
    ];

    haciendaId:number=0;

    async guardar()
    {
     let detalle:Array<ConsumoDetalleAddRequest> = [];
      try
      {
        this.changeLoading(new Loading(true,"Agregando detalle....Espere por favor"));
        if(this.listadoTrozaSeleccionada.length > 0)
        {
            this.listadoTrozaSeleccionada.forEach(c=> {
                detalle.push(new ConsumoDetalleAddRequest(this.consumo.consumoId,c.recepcionId!,0));
            })
        }
        if(this.listadoTablonSeleccionada.length > 0)
        {
            this.listadoTablonSeleccionada.forEach(c=> {
                detalle.push(new ConsumoDetalleAddRequest(this.consumo.consumoId,c.recepcionId!,c.recepcionDetalleId!))
                
            })
        }
     
        var rsp = await AgregarDetalleConsumo(detalle);
        if(rsp.isSuccess == true)
        {
            this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
            this.$emit('limpiar');
            return;

        }
        this.changeAlerta(new Alerta(rsp.isMessage,true,"danger",3000));
      }
      catch(error)
      {
        console.log(error);
      }
      finally
      {
        this.changeLoading(new Loading(false,""));
      }

    }
    mounted() {
        console.log(this.tablon);   
    }
}
